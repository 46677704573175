import PropTypes from 'prop-types';

const SearchBox = (props) => {
  const {
    searchTerm,
    onSearchTermChanged,
  } = props;

  return (
    <div className="container text-black">
      <input id="searchfield" type="search" value={searchTerm} onChange={(e) => onSearchTermChanged(e.target.value)} placeholder="Search..." autoFocus="autoFocus" className="text-grey-800 transition focus:outline-none focus:border p-2 appearance-none leading-normal border" />
    </div>
  )
}

SearchBox.propTypes = {
  searchTerm: PropTypes.string,
  onSearchTermChanged: PropTypes.func,
}

SearchBox.defaultProps = {
  searchTerm: '',
  onSearchTermChanged: () => { },
}

export { SearchBox };
