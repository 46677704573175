import { getApiBaseUrl } from "../helpers/url-helper";

const getStrapiMediaFromServerModel = (mediaObj) => {
  const baseUrl = getApiBaseUrl();

  // When media is fetched directly, the object is body but it is fetched as attributes, it is within attributes.
  const body = mediaObj.attributes ? mediaObj.attributes : mediaObj;

  const newMedia = {
    id: mediaObj.id,
    original: {
      url: baseUrl + body.url,
      width: body.width,
      height: body.height,
    },
    thumbnail: {
      url: baseUrl + body.formats.thumbnail.url,
      width: body.formats.thumbnail.width,
      height: body.formats.thumbnail.height,
    },
    small: {
      url: baseUrl + body.formats.small.url,
      width: body.formats.small.width,
      height: body.formats.small.height,
    },
  };

  return newMedia;
};

export { getStrapiMediaFromServerModel }