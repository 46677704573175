import { createContext, useState } from 'react';
import useIngredients from './use-ingredients';

const IngredientContext = createContext();

const IngredientContextProvider = ({ children }) => {
  const { ingredients, reloadIngredientsAsync } = useIngredients();
  const [usersIngredients, setSelectedIngredients] = useState([]);
  const addUserIngredient = (item) => {
    setSelectedIngredients([
      ...usersIngredients,
      item,
    ]);
  };

  const removeUserIngredient = (item) => {
    setSelectedIngredients(usersIngredients.filter((ingredient) => ingredient.id !== item.id));
  }

  const reloadIngredients = () => {
    reloadIngredientsAsync();
  };

  return (
    <IngredientContext.Provider value={{
      ingredients,
      usersIngredients,
      addUserIngredient,
      removeUserIngredient,
      reloadIngredients,
    }}>
      {children}
    </IngredientContext.Provider>
  )
};

export { IngredientContext, IngredientContextProvider };