import { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { SearchBox } from "../common";
import { IngredientItemsCollectionsWithUserIngredients, IngredientItemsCollectionTypes } from ".";
import { searchIngredients } from "../../repository/ingredients";
import { IngredientItemsCollections } from "./ingredient-items-collection";
import { ingredientType } from '../../types';

const IngredientSearch = (props) => {
  const {
    withUserIngredients,
    selectedIngredients,
    onAdd,
  } = props;
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResult, setSearchResult] = useState([]);

  useEffect(() => {
    searchIngredients(searchTerm).then((data) => {
      setSearchResult(data.data);
    });
  }, [searchTerm]);

  return (
    <>
      <SearchBox searchTerm={searchTerm} onSearchTermChanged={setSearchTerm} />
      <div className="selected-ingredients-container">
        {
          withUserIngredients &&
          <IngredientItemsCollectionsWithUserIngredients
            ingredients={searchResult}
            type={IngredientItemsCollectionTypes.extended}
            canAdd
          />
        }
        {
          !withUserIngredients &&
          <IngredientItemsCollections
            ingredients={searchResult}
            selectedIngredients={selectedIngredients}
            canAdd
            onAdd={onAdd}
          />
        }

      </div>
    </>
  )
};

IngredientSearch.propTypes = {
  withUserIngredients: PropTypes.bool.isRequired,
  selectedIngredients: PropTypes.arrayOf(ingredientType),
  onAdd: PropTypes.func,
};

IngredientSearch.defaultProps = {
  selectedIngredients: [],
  onAdd: () => { },
}

export { IngredientSearch };
