const Modal = (props) => {
  const { children, isShow } = props;

  return (
    <>
      {
        isShow &&
        (
          <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="flex items-start justify-center min-h-screen pt-2 px-1 pb-2 text-center">
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle max-w-screen-xl w-full">
                {children}
              </div>
            </div>
          </div>
        )
      }
    </>
  );
}

export { Modal };
