import qs from 'qs';

const _postProcessFetchedIngredient = (fetchedIngredient) => {
  const data = {
    id: fetchedIngredient.id,
    ...fetchedIngredient.attributes,
  };

  if (data.recipes && data.recipes.data) {
    data.recipes = data.recipes.data.map((item) => item.id);
  }

  return data;
};

const _postProcessFetchedIngredients = (fetchedResult) => {
  const convertedData = fetchedResult.data.map((item) => {
    return _postProcessFetchedIngredient(item);
  });

  fetchedResult.data = convertedData;

  return fetchedResult;
}

const getIngredientData = async (id) => {
  const data = await fetch(`https://api.piano8283.com/api/ingredients/${id}`).then((res) => {
    return res.json();
  });

  const res = {
    id: `${data.data.id}`,
    ...data.data.attributes,
  };

  return res;
}

const getIngredientsByIds = (ids) => {
  const qs = require('qs');
  const query = qs.stringify({
    filters: {
      id: {
        $in: ids,
      },
    },
    populate: {
      recipes: {
        fields: ['id'],
      },
    },
    pagination: {
      page: 1,
      pageSize: 200,
    }
  }, {
    encodeValuesOnly: true,
  });

  return fetch(`https://api.piano8283.com/api/ingredients?${query}`).then((res) => {
    return res.json();
  }).then((data) => {
    return _postProcessFetchedIngredients(data);
  });
};

const searchIngredients = async (searchTerm) => {
  const qs = require('qs');
  const query = qs.stringify({
    filters: {
      name: {
        $containsi: searchTerm,
      },
    },
  }, {
    encodeValuesOnly: true,
  });

  const data = await fetch(`https://api.piano8283.com/api/ingredients?${query}`).then((res) => {
    return res.json();
  });

  return _postProcessFetchedIngredients(data);
}

const getIngredients = async () => {
  const query = qs.stringify({
    sort: ['updatedAt:desc'],
  }, {
    encodeValuesOnly: true,
  });

  const data = await fetch(`https://api.piano8283.com/api/ingredients?${query}`).then((res) => {
    return res.json();
  });

  return _postProcessFetchedIngredients(data);
}

const postIngredient = async (newIngredient) => {
  const data = await fetch('https://api.piano8283.com/api/ingredients', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ data: newIngredient }),
  })
    .then(response => response.json());

  return data;
}


export { getIngredientData, getIngredients, postIngredient, searchIngredients, getIngredientsByIds };