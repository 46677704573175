import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { ingredientType } from '../../types';

const IngredientItem = (props) => {
  const {
    ingredient,
    selectedIngredients,
    onAdd,
    onRemove,
    canRemove,
    canAdd,
  } = props;

  const isSelected = useMemo(() => {
    return !!selectedIngredients.find(item => item.id === ingredient.id);
  }, [selectedIngredients, ingredient.id]);

  const containerClassName = useMemo(() => {
    return `ingredient-item inline-block text-white px-2 py-1 m-1 leading-none items-center space-x-2 ${isSelected ? 'bg-green-600' : 'bg-green-400'}`
  }, [isSelected]);

  return (
    <div
      className={containerClassName}
      key={ingredient.id}
    >
      <div className="inline-block">{ingredient.name}</div>
      {
        canRemove &&
        <button
          className="text-white bg-green-800 p-2 inline-block"
          onClick={() => onRemove(ingredient)}
        >x</button>
      }
      {
        canAdd &&
        <button
          className="text-white bg-green-800 p-2 inline-block"
          disabled={isSelected}
          onClick={() => onAdd(ingredient)}
        >+</button>
      }
    </div>
  );
}

IngredientItem.propTypes = {
  ingredient: ingredientType.isRequired,
  selectedIngredients: PropTypes.arrayOf(ingredientType),
  canRemove: PropTypes.bool,
  canAdd: PropTypes.bool,
  onRemove: PropTypes.func,
  onAdd: PropTypes.func,
};

IngredientItem.defaultProps = {
  canRemove: false,
  canAdd: false,
  onRemove: () => { },
  onAdd: () => { },
  selectedIngredients: [],
}

export { IngredientItem };