import { IngredientItemsCollectionsWithUserIngredients } from "../ingredients";
import { Button } from "../common";

const RecipesTable = (props) => {
  const { recipes, openCrud, showAddRecipe } = props;

  return (
    <div className="grid grid-cols-1 2xl:grid-cols-2 gap-4 mt-5">
      {
        showAddRecipe &&
        <div className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
          <p>Want to register your own recipe?</p>
          <Button onClick={() => openCrud()}>Add Recipe</Button>
        </div>
      }
      {
        recipes.map((recipe) => {
          return (
            <div
              className="border-b border-gray-200 bg-white text-sm flex"
              key={recipe.id}
              onClick={() => openCrud(recipe.id)}
            >
              <div className="px-5 py-5 flex-initial w-64">
                <div className="flex items-center">
                  <div className="flex-shrink-0 w-28 h-20">
                    <img className="w-full h-full rounded"
                      src={recipe.images.length > 0 ? recipe.images[0].thumbnail.url : '/assets/recipe-default.svg'}
                      alt="" />
                  </div>
                  <div className="ml-3">
                    <p className="text-gray-900 whitespace-no-wrap">
                      {recipe.name}
                    </p>
                  </div>
                </div>
              </div>
              <div className="px-5 py-5 flex-initial">
                <p className="text-gray-900 whitespace-no-wrap">{recipe.description}</p>
              </div>
              <div className="px-5 py-5 flex-initial">
                <IngredientItemsCollectionsWithUserIngredients ingredients={recipe.ingredients} />
              </div>
            </div>)
        })
      }

    </div>
  )
}

export { RecipesTable };