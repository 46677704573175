const UiBlocker = (props) => {
  const { isBlocked, children } = props;

  return (
    <div className="ui-block-container relative">
      <div className="ui-block-content relative">
        {children}
      </div>
      {
        isBlocked &&
        <div className="ui-block-block bg-white/50 absolute top-0 left-0 bottom-0 right-0 flex items-center justify-center">
          <div className="flex items-center justify-center space-x-2 animate-pulse">
            <div className="w-4 h-4 bg-blue-400 rounded-full"></div>
            <div className="w-4 h-4 bg-blue-400 rounded-full"></div>
            <div className="w-4 h-4 bg-blue-400 rounded-full"></div>
          </div>
        </div>
      }
    </div>
  )
};

export { UiBlocker };