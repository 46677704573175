import { useEffect, useState } from 'react';
import { getRecipes } from '../../repository/recipes';
import { RecipesCollection } from '../../components/recipes';

export default function Dashboard() {
  const [recipes, setRecipes] = useState([]);

  useEffect(() => {
    getRecipes().then((data) => {
      setRecipes(data.data);
    });
  }, [setRecipes]);

  return (
    <>
      <RecipesCollection recipes={recipes} />
    </>
  )
}