import PropTypes from 'prop-types';

const ingredientType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
});

const ingredientItemType = PropTypes.shape({
  ingredient: ingredientType.isRequired,
  canRemove: PropTypes.bool,
  canAdd: PropTypes.bool,
});

export { ingredientType, ingredientItemType };
