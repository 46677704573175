import { useMemo, useState } from 'react';
import { IngredientItemsCollections, IngredientItemsCollectionTypes, IngredientSearch } from '../ingredients';
import { saveRecipeAsync } from '../../repository/recipes';
import { uploadImageAsync } from '../../repository/uploads';
import { UiBlocker } from '../common/ui-blocker';

const RecipeCrud = (props) => {
  const { recipe, closeCrud } = props;

  const hasData = recipe ? !!recipe.id : false;
  const [isEditMode, setIsEditMode] = useState(!hasData);
  const [isUiBlocked, setIsUiBlocked] = useState(false);
  const [name, setName] = useState(hasData ? recipe.name : '');
  const [description, setDescription] = useState(hasData ? recipe.description : '');
  const [images, setImages] = useState(hasData ? recipe.images : []);
  const [ingredients, setIngredients] = useState(hasData ? recipe.ingredients : []);
  const thumbnail = useMemo(() => {
    if (images.length > 0) {
      return images[0].small.url;
    }

    return null;
  }, [images]);

  const onSave = () => {
    setIsUiBlocked(true);
    saveRecipeAsync({
      id: hasData ? recipe.id : null,
      name,
      description,
      images: images.map(image => image.id),
      ingredients: ingredients.map(ingredient => ingredient.id),
    }).then((/* response */) => {
      // do something with response
    }).finally(() => {
      setIsUiBlocked(false);
      closeCrud();
    });
  };

  const onImageFileSet = event => {
    if (event.target.files && event.target.files[0]) {
      let imgFile = event.target.files[0];
      setIsUiBlocked(true);
      uploadImageAsync(imgFile).then((media) => {
        setImages([media]);
      }).finally(() => {
        setIsUiBlocked(false);
      });
    }
  };

  const onAddIngredient = newIngredient => {
    setIngredients([
      ...ingredients,
      newIngredient,
    ]);
  }

  const onRemoveIngredient = ingredientToRemove => {
    setIngredients(ingredients.filter((ingredient) => ingredient.id !== ingredientToRemove.id));
  }

  return (
    <UiBlocker isBlocked={isUiBlocked}>
      <div className="bg-white p-8 sm:p-6">
        {
          isEditMode &&
          <>
            <label htmlFor="name" className="text-sm font-medium text-gray-900 block mb-2">Recipe Name</label>
            <input type="text" id="name" value={name} onChange={e => setName(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
          </>
        }
        {
          !isEditMode && <h1 className="font-semibold text-xl">{name}</h1>
        }
      </div>
      <div className="p-10 grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-5">
        <div className="relative pb-48 overflow-hidden">
          {
            thumbnail &&
            <img
              className="absolute inset-0 h-full w-full object-cover hover:scale-105 transition duration-300"
              src={thumbnail}
              alt="" />
          }
          <label
            className="cursor-pointer inline-block mt-0 px-5 py-2 bg-green-500 text-lg text-white font-semibold hover:bg-green-700 transition duration-300"
            htmlFor="image-file-input">
            Pick an image to use
          </label>
          <input
            className="hidden"
            id="image-file-input"
            type="file"
            accept="image/*"
            onChange={onImageFileSet} />
        </div>
        <IngredientItemsCollections
          ingredients={ingredients}
          type={IngredientItemsCollectionTypes.extended}
          canRemove={isEditMode}
          onRemove={onRemoveIngredient}
        />
        {
          isEditMode &&
          <IngredientSearch
            withUserIngredients={false}
            selectedIngredients={ingredients}
            onAdd={onAddIngredient}
          />
        }
      </div>
      <div className="p-8 sm:p-6">
        {!isEditMode &&
          <div>
            {description}
          </div>
        }
        {
          isEditMode &&
          <>
            <label htmlFor="description" className="text-sm font-medium text-gray-900 block mb-2">Recipe description</label>
            <input type="text" id="name" value={description} onChange={e => setDescription(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
          </>

        }
      </div>
      <div className="bg-gray-50 mt-3 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <button onClick={closeCrud} type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-white text-base font-medium hover:bg-grey-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm">
          Close
        </button>
        {
          isEditMode &&
          <button onClick={onSave} type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
            Save
          </button>
        }
        {
          !isEditMode &&
          <button onClick={() => setIsEditMode(true)} type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
            Edit
          </button>
        }
      </div>
    </UiBlocker>
  )
};

export { RecipeCrud };