import { useState, useCallback, useContext } from 'react';
import { postIngredient } from '../../repository/ingredients';
import { IngredientContext } from '../../contexts/ingredient-context';
import { Button } from '../common/button';

const IngredientCrud = () => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const { reloadIngredients } = useContext(IngredientContext);

  const onAddClicked = useCallback(() => {
    postIngredient({
      name: name,
      description: description,
    }).then((data) => {
      // succeeded
      if (data.data.id) {
        setName('');
        setDescription('');

        reloadIngredients();
      }
    });
  }, [description, name, reloadIngredients]);

  return (
    <form>
      <div className="mb-6">
        <label htmlFor="name" className="text-sm font-medium text-gray-900 block mb-2">Ingredient name</label>
        <input type="text" id="name" value={name} onChange={e => setName(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
      </div>
      <div className="mb-6">
        <label htmlFor="description" className="text-sm font-medium text-gray-900 block mb-2">description</label>
        <input type="text" id="description" value={description} onChange={e => setDescription(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required="" />
      </div>
      <Button onClick={onAddClicked}>
        Add new ingredient
      </Button>
    </form>
  )
}

export default IngredientCrud;