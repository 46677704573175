import { Link } from "react-router-dom";
import { useState } from "react";

const Links = ['Dashboard', 'Recipes', 'Ingredients', 'About'];

const NavLink = ({ children }) => {
  return (
    <Link
      to={`/${children.toLowerCase()}`}>
      <div className="py-4 px-2 text-gray-500 font-semibold hover:text-green-500 transition duration-300">
        {children}
      </div>
    </Link>
  );
}

const NavLinkMobile = ({ children }) => {
  return (
    <Link
      to={`/${children.toLowerCase()}`}>
      <div className="block text-sm px-2 py-4 hover:bg-green-500 transition duration-300">
        {children}
      </div>
    </Link>
  );
}


export default function Header({ children }) {
  const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false);

  return (
    <>
      <nav className="bg-white shadow-lg">
        <div className="max-w-6xl mx-auto px-4">
          <div className="flex justify-between">
            <div className="flex space-x-7">
              <div>
                <Link to="/" className="flex items-center py-4 px-2">
                  {/* <img src="logo.png" alt="Logo" className="h-8 w-8 mr-2" /> */}
                  <span className="font-semibold text-gray-500 text-lg p-2 hover:bg-green-500 hover:text-white transition duration-300">Piano8283 Kitchen</span>
                </Link>
              </div>
              {/* <!-- Primary Navbar items --> */}
              <div className="hidden md:flex items-center space-x-1">
                {Links.map((link) => (
                  <NavLink key={link}>{link}</NavLink>
                ))}
              </div>
            </div>
            {/* <!-- Secondary Navbar items --> */}
            <div className="hidden md:flex items-center space-x-3 ">
              <Link to="/login" className="py-2 px-2 font-medium text-gray-500 hover:bg-green-500 hover:text-white transition duration-300">Log In</Link>
              <Link to="/signup" className="py-2 px-2 font-medium text-white bg-green-500 hover:bg-green-400 transition duration-300">Sign Up</Link>
            </div>
            {/* <!-- Mobile menu button --> */}
            <div className="md:hidden flex items-center">
              <button
                className="outline-none mobile-menu-button"
                onClick={() => setIsMobileMenuOpened(!isMobileMenuOpened)}
              >
                <svg className=" w-6 h-6 text-gray-500 hover:text-green-500 "
                  x-show="!showMenu"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M4 6h16M4 12h16M4 18h16"></path>
                </svg>
              </button>
            </div>
          </div>
        </div>


        <div className={`${isMobileMenuOpened ? '' : 'hidden'} mobile-menu`}>
          {Links.map((link) => (
            <NavLinkMobile key={link}>{link}</NavLinkMobile>
          ))}
        </div>
      </nav>

      <div>
        {children}
      </div>
    </>
  );
}