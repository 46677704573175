import { IngredientItemsCollectionsWithUserIngredients } from '../ingredients';

const RecipeListItem = (props) => {
  const {
    name,
    description,
    thumbnail,
    ingredients,
    onClick,
  } = props;

  return (
    <div className="rounded overflow-hidden shadow-lg" onClick={onClick}>
      <div className="relative pb-48 overflow-hidden">
        <img
          className="absolute inset-0 h-full w-full object-cover hover:scale-105 transition duration-300"
          src={thumbnail ? thumbnail : '/assets/recipe-default.svg'}
          alt="" />
      </div>
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2">{name}</div>
        <p className="text-gray-700 text-base">
          {description}
        </p>
      </div>
      <div className="px-6 pt-4 pb-2">
        <IngredientItemsCollectionsWithUserIngredients ingredients={ingredients} />
      </div>
    </div>
  )
}

export { RecipeListItem }