import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './components/header';
import Dashboard from './pages/dashboard';
import Recipes from './pages/recipes';
import Ingredients from './pages/ingredients';
import { IngredientContextProvider } from './contexts/ingredient-context';

function App() {
  return (
    <div className="App bg-slate-50">
      <IngredientContextProvider>
        <BrowserRouter>
          <Header />
          <Routes>
            <Route path='/' exact element={<Dashboard />} />
            <Route path='/recipes' exact element={<Recipes />} />
            <Route path='/ingredients' exact element={<Ingredients />} />
            <Route path='/dashboard' exact element={<Dashboard />} />
          </Routes>
        </BrowserRouter>
      </IngredientContextProvider>
    </div>
  );
}

export default App;
