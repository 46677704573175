
import PropTypes from 'prop-types';
import { IngredientItem } from './ingredient-item';
import { ingredientType } from '../../types';

const IngredientItemsCollectionTypes = {
  tags: 'tags',
  extended: 'extended',
};

const typeToIngredientItemsContainerClassNames = {
  tags: 'ingredient-items-tag-container -mx-1 mt-8',
  extended: 'ingredient-items-extended-container -mx-1 mt-8',
};

const IngredientItemsCollections = (props) => {
  const {
    ingredients,
    selectedIngredients,
    type,
    canAdd,
    canRemove,
    onAdd,
    onRemove,
  } = props;

  const containerClassName = typeToIngredientItemsContainerClassNames[type];

  return (
    <div className={containerClassName}>
      {
        ingredients.map((ingredient) => {
          return (<IngredientItem
            key={ingredient.id}
            ingredient={ingredient}
            selectedIngredients={selectedIngredients}
            canAdd={canAdd}
            canRemove={canRemove}
            onAdd={onAdd}
            onRemove={onRemove}
          />)
        })
      }
    </div>
  )
}

IngredientItemsCollections.propTypes = {
  ingredients: PropTypes.arrayOf(ingredientType).isRequired,
  selectedIngredients: PropTypes.arrayOf(ingredientType),
  type: PropTypes.string,
  canAdd: PropTypes.bool,
  canRemove: PropTypes.bool,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
};

IngredientItemsCollections.defaultProps = {
  type: IngredientItemsCollectionTypes.tags,
  selectedIngredients: [],
  canAdd: false,
  canRemove: false,
  onAdd: () => { },
  onRemove: () => { },
};

export { IngredientItemsCollections, IngredientItemsCollectionTypes };
