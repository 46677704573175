import { useEffect, useState, useCallback } from 'react';
import { getRecipes } from '../../repository/recipes';
import { RecipeCrud, RecipesTable } from '../../components/recipes';
import { Modal } from '../../components/common';

export default function Recipes() {
  const [recipes, setRecipes] = useState([]);
  const [selectedRecipe, setSelectedRecipe] = useState(null);
  const [showCrud, setShowCrud] = useState(false);

  useEffect(() => {
    getRecipes().then((data) => {
      setRecipes(data.data);
    });
  }, [setRecipes]);

  const openCrud = useCallback((id) => {
    setShowCrud(true);

    if (id) {
      setSelectedRecipe(recipes.find((recipe) => recipe.id === id));
    }
  }, [recipes]);

  const closeCrud = useCallback(() => {
    setShowCrud(false);
    setSelectedRecipe({});
  }, []);

  return (
    <>
      <Modal
        isShow={showCrud}
        close={closeCrud}
      >
        <RecipeCrud recipe={selectedRecipe} closeCrud={closeCrud} />

      </Modal>
      <RecipesTable recipes={recipes} openCrud={openCrud} showAddRecipe />
    </>
  )
}