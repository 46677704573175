const Section = (props) => {
  const {
    title,
    description,
    children,
  } = props;

  return (
    <section className="m-5 shadow-md p-10 bg-white">
      <div className="flex justify-items-center items-center">
        <div>
          <h1 className="text-3xl font-semibold font-serif mb-6">
            <span className="text-green-700">{title}</span>
          </h1>
          <p className="text-lg max-w-xl">{description}</p>
        </div>
      </div>
      {children}
    </section>
  )
}

export { Section };