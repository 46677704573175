import PropTypes from 'prop-types'
import { RecipeListItem } from './recipe-list-item';

const RecipesCollection = (props) => {
  const { recipes, openCrud, showAddRecipe } = props;

  return (
    <div className="p-10 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xxl:grid-cols-5 gap-5">
      {
        showAddRecipe &&
        <RecipeListItem
          name="Add New Recipe"
          description="You can add your own recipe"
          thumbnail="/assets/recipe-add.svg"
          ingredients={[]}
          onClick={() => openCrud()}
        />
      }

      {
        recipes.map((recipe) => {
          return (
            <RecipeListItem
              key={recipe.id}
              id={recipe.id}
              name={recipe.name}
              description={recipe.description}
              thumbnail={recipe.images.length > 0 ? recipe.images[0].thumbnail.url : null}
              ingredients={recipe.ingredients}
              onClick={() => openCrud(recipe.id)}
            />
          )
        })
      }
    </div>
  )
};

RecipesCollection.propTypes = {
  showAddRecipe: PropTypes.bool,
};

RecipesCollection.defaultProps = {
  showAddRecipe: false,
}

export { RecipesCollection };

