import { useEffect, useState } from 'react';
import { getIngredients } from '../repository/ingredients'

const useIngredients = () => {
  const [ingredients, setIngredients] = useState([]);

  const reloadIngredientsAsync = async () => {
    return getIngredients().then((data) => {
      setIngredients(data);
    });
  };

  useEffect(() => {
    reloadIngredientsAsync();
  }, []);

  return { ingredients, reloadIngredientsAsync };
};

export default useIngredients;