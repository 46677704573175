import { getStrapiMediaFromServerModel } from "../models/strapi-media";

const uploadImageAsync = (imageFile) => {
  const formData = new FormData()

  formData.append('files', imageFile);

  return fetch('https://api.piano8283.com/api/upload', {
    method: 'POST',
    body: formData,
  }).then((response) => {
    return response.json();
  }).then((data) => {
    return getStrapiMediaFromServerModel(data[0]);
  });
};

export { uploadImageAsync };
