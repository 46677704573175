

const Button = (props) => {
  return (
    <button
      type="button"
      onClick={props.onClick}
      className="inline-block mt-0 px-5 py-2 bg-green-500 text-lg text-white font-semibold hover:bg-green-700 transition duration-300">
      {props.children}
    </button>
  )
}

export { Button };