
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { IngredientItemsCollections, IngredientItemsCollectionTypes } from './ingredient-items-collection';
import { IngredientContext } from '../../contexts/ingredient-context';
import { ingredientType } from '../../types';

const IngredientItemsCollectionsWithUserIngredients = (props) => {
  const {
    type,
    ingredients,
    canAdd,
    canRemove,
  } = props;

  const {
    usersIngredients,
    addUserIngredient,
    removeUserIngredient,
  } = useContext(IngredientContext);

  const onAdd = (ingredient) => {
    addUserIngredient({
      id: ingredient.id,
      name: ingredient.name,
    });
  }

  const onRemove = (ingredient) => {
    removeUserIngredient({
      id: ingredient.id,
    });
  }

  return (
    <IngredientItemsCollections
      ingredients={ingredients}
      selectedIngredients={usersIngredients}
      type={type}
      canAdd={canAdd}
      canRemove={canRemove}
      onAdd={onAdd}
      onRemove={onRemove}
    />
  )
}

IngredientItemsCollectionsWithUserIngredients.propTypes = {
  ingredients: PropTypes.arrayOf(ingredientType).isRequired,
  type: PropTypes.string,
  canAdd: PropTypes.bool,
  canRemove: PropTypes.bool,
};

IngredientItemsCollectionsWithUserIngredients.defaultProps = {
  type: IngredientItemsCollectionTypes.tags,
  canAdd: false,
  canRemove: false,
};

export { IngredientItemsCollectionsWithUserIngredients };
