import qs from 'qs';
import { getStrapiMediaFromServerModel } from '../models/strapi-media';

const _postProcessFetchedRecipe = (item) => {
  const data = {
    id: item.id,
    ...item.attributes,
  };

  if (data.ingredients && data.ingredients.data) {
    data.ingredients = data.ingredients.data.map((item) => item.id);
  }

  return data;
};

const _postProcessFetchedRecipes = (fetchedResult) => {
  const convertedData = fetchedResult.data.map((item) => {
    return _postProcessFetchedRecipe(item);
  });

  fetchedResult.data = convertedData;

  return fetchedResult;
}

const getRecipeIds = async () => {
  const data = await fetch('https://api.piano8283.com/api/recipes?fields=id').then((res) => {
    return res.json();
  });

  const res = data.data.map((item) => {
    return {
      params: {
        id: `${item.id}`,
      }
    }
  })

  return res;
}

const getRecipeData = async (id) => {
  const query = qs.stringify({
    populate: ['images', 'ingredients'],
  }, {
    encodeValuesOnly: true,
  });
  const data = await fetch(`https://api.piano8283.com/api/recipes/${id}?${query}`).then((res) => {
    return res.json();
  });

  const res = {
    id: `${data.data.id}`,
    ...data.data.attributes,
  };

  return res;
}

const saveRecipeAsync = (recipe) => {
  if (!recipe.id) {
    return fetch('https://api.piano8283.com/api/recipes', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ data: recipe }),
    })
      .then(response => response.json());
  } else {
    return fetch(`https://api.piano8283.com/api/recipes/${recipe.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ data: recipe }),
    })
      .then(response => response.json());
  }
};

const getRecipes = async () => {
  const query = qs.stringify({
    sort: ['updatedAt:desc'],
    populate: ['images', 'ingredients'],
    // populate: [
    //   {
    //     images: {
    //       fields: ['name', 'url'],
    //     }
    //   },
    // ],
  }, {
    encodeValuesOnly: true,
  });

  const data = await fetch(`https://api.piano8283.com/api/recipes?${query}`).then((res) => {
    return res.json();
  }).then((data) => {
    const formattedData = data.data.map((item) => {
      let images = [];
      if (item.attributes.images.data) {
        images = item.attributes.images.data.map((fetchedImageObj) => getStrapiMediaFromServerModel(fetchedImageObj));
      }

      let ingredients = [];

      for (let ingredient of item.attributes.ingredients.data) {
        ingredients.push({
          id: ingredient.id,
          name: ingredient.attributes.name,
        });
      }

      return {
        id: item.id,
        description: item.attributes.description,
        name: item.attributes.name,
        images,
        ingredients,
      };
    });

    data.data = formattedData;

    return data;
  });

  return data;
}

const getRecipesByIngredientIds = (ingredientIds) => {
  const qs = require('qs');
  const query = qs.stringify({
    populate: {
      ingredients: {
        fields: ['id'],
      },
    },
    pagination: {
      page: 1,
      pageSize: 200,
    }
  }, {
    encodeValuesOnly: true,
  });

  return fetch(`https://api.piano8283.com/api/recipes?${query}`).then((res) => {
    return res.json();
  }).then((data) => {
    return _postProcessFetchedRecipes(data);
  }).then((recipes) => {
    const filteredRecipes = recipes.data.filter((recipe) => {
      const filteredIngredients = recipe.ingredients.filter(value => ingredientIds.includes(value));

      return filteredIngredients.length === recipe.ingredients.length;
    });

    return filteredRecipes;
  });
};


export { getRecipeIds, getRecipeData, getRecipes, saveRecipeAsync, getRecipesByIngredientIds };