import { useContext } from 'react';
import { IngredientContext } from '../../contexts/ingredient-context';
import {
  IngredientCrud,
  IngredientItemsCollectionsWithUserIngredients,
  IngredientSearch,
} from '../../components/ingredients';
import { Button, Section } from '../../components/common';
import { getRecipesByIngredientIds } from '../../repository/recipes';

export default function Ingredients() {
  const { usersIngredients } = useContext(IngredientContext);

  const onShowRecipes = () => {
    const idsToSearch = usersIngredients.map((item) => item.id);

    getRecipesByIngredientIds(idsToSearch).then((recipes) => {

      console.log(recipes);
    })
  };

  return (
    <>
      <Section title="Ingredients you have" description="You can select ingredients and we will show you the recipes.">
        <Button type="button" onClick={onShowRecipes}>Show recipes</Button>
        <div className="selected-ingredients-container">
          <IngredientItemsCollectionsWithUserIngredients ingredients={usersIngredients} canRemove />
        </div>
      </Section>

      <Section title="Ingredients you can select" description="You can select or add your own ingredients.">
        <IngredientSearch withUserIngredients />
        <p className="mt-5 text-lg max-w-md">Want to register your own ingredient?</p>
        <IngredientCrud />
      </Section>
    </>
  )
}